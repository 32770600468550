import { BAG_EMPTY_MESSAGE, BAG_EMPTY_TEXT, BTN_START_SHOPPING, } from '@components/utils/textVariables'
import Link from 'next/link'
import { handleClose } from './helper'
import { useUI } from '@components/ui'
import { IMAGE_ASSET_BASE_URL } from '@framework/utils/constants'
interface IEmptyCart {
  currentPage: string
  previousPath: string
}

const EmptyCart = (props: IEmptyCart) => {
  const { currentPage, previousPath, } = props
  const { closeSidebar } = useUI()

  return (
    <>
      <div className="flex flex-col w-full h-full p-8 text-gray-900 bg-gray-100">
        <div className="flex flex-col">
          <img
            alt="test"
            src={`${IMAGE_ASSET_BASE_URL}/icons/empty-bag.svg`}
            width={100}
            height={128}
          />
          <h3 className="mt-4 mb-2 text-lg font-bold text-black dark:text-black">
            {BAG_EMPTY_MESSAGE}
          </h3>
          <p className="text-sm font-normal text-gray-500 dark:text-gray-500">
            {BAG_EMPTY_TEXT}
          </p>
        </div>
        <div className="flex flex-col w-full mt-4 text-center">
          <Link legacyBehavior href="/men/all" passHref>
            <a
              onClick={() =>
                handleClose(closeSidebar, currentPage, previousPath)
              }
              className="w-full py-3 font-semibold text-white bg-black rounded text-md hover:bg-gray-800"
            >
              {BTN_START_SHOPPING}
            </a>
          </Link>
        </div>
      </div>
    </>
  )
}

export default EmptyCart
