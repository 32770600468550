import { matchStrings, numberToString } from '@framework/utils/parse-util'
import {
  BETTERCOMMERCE_DEFAULT_COUNTRY,
  BETTERCOMMERCE_DEFAULT_COUNTRY_CODE,
  CONVERT_ORDER_VALIDATION_MESSAGES,
  EmptyGuid,
  EmptyString,
  Messages,
  NEXT_DELIVERABILITY_ETA,
  NEXT_SAVE_CUSTOM_INFO,
  NEXT_SHIPPING_ENDPOINT,
  UtmCookieKey,
} from '@components/utils/constants'
import {
  LocalStorage,
  PaymentOrderStatus,
} from '@components/utils/payment-constants'
import axios from '@services/axiosInterceptorInstance'
import {
  APPLY_JUSPAY_OFFERS_DISABLED,
  GOKWIK_COD_METHOD_SYSTEM_NAME,
  TEST_PAYMENT_AMOUNT,
} from '@framework/utils/constants'
import {
  createJusPayOrder,
  createStorefrontOrder,
} from '@framework/utils/juspay-util'
import { decrypt, encrypt } from '@framework/utils/cipher'
import {
  IUseOrdersHookProps,
  fetchPaymentMethod,
  getAdditionalCharges,
  getJusPayOfferDetails,
} from '../helper'
import { toNumber } from '@framework/utils/lodash'
import { fetchEDDAsync, getCampaignInfo, getOrderId, getOrderInfo, getRtoInfo } from '@framework/utils/app-util'
import { getOrderModelData } from '../eventsHelper'
import Cookies from 'js-cookie'

const useOrders = ({
  isChannelStore,
  storeBasketId,
  basketId,
  getUserId,
  shippingCountry,
  user,
  cartItems,
  isTestPayment,
  setBillableAmount,
  recordFacebookPaymentInfo,
  setOrderInfo,
  defaultShippingAddress,
  applicableCoupon,
  applicableOffers,
  resetCartStorage,
  paymentMethods,
  juspayPaymentMethodName,
  defaultBillingAddress,
  setAlert,
  generateNewBasketAndRedirect,
  paymentMethod,
}: IUseOrdersHookProps) => {
  const checkForDefaultBillingAddress = (billingAddress: any) => {
    if (billingAddress && billingAddress.id !== '000') {
      return billingAddress
    } else {
      return {
        ...billingAddress,
        firstName: billingAddress?.firstName || user?.firstName,
        lastName: billingAddress?.lastName || user?.lastName,
        mobileNo:
          billingAddress?.mobileNo ||
          user?.mobile ||
          user?.telephone ||
          user?.username,
        phoneNo:
          billingAddress?.phoneNo ||
          user?.mobile ||
          user?.telephone ||
          user?.username,
      }
    }
  }

  const confirmOrder = async (
    eta: any,
    method: any,
    shippingAddress: any,
    shippingMethod: any,
    paymentMethodName?: string,
    additionalServiceCharge?: any,
    isCOD?: boolean
  ) => {
    const isCncMethod = matchStrings(shippingMethod?.shippingCode, 'CNC')
    const billingInfoClone = { ...shippingAddress }
    //delete billingInfoClone.id;
    const shippingClone = { ...shippingAddress }
    //delete shippingClone.id;

    let confirmOrderInput: any = {
      basketId: isChannelStore ? storeBasketId : basketId,
      customerId: getUserId(),
      basket: null, //cartItems,
      billingAddress: {
        ...billingInfoClone,
        isConsentSelected: user?.notifyByWhatsapp,
        country:
          shippingCountry?.twoLetterIsoCode || BETTERCOMMERCE_DEFAULT_COUNTRY,
        countryCode:
          shippingCountry?.twoLetterIsoCode || BETTERCOMMERCE_DEFAULT_COUNTRY,
      },
      shippingAddress: {
        ...shippingClone,
        isConsentSelected: user?.notifyByWhatsapp,
        country:
          shippingCountry?.twoLetterIsoCode || BETTERCOMMERCE_DEFAULT_COUNTRY,
        countryCode:
          shippingCountry?.twoLetterIsoCode || BETTERCOMMERCE_DEFAULT_COUNTRY,
      },
      selectedShipping: shippingMethod,
      selectedPayment: method,
      storeId: '', //state.storeId, TODO: store id for CNC implementation.
      Payment: {
        Id: null,
        CardNo: null,
        OrderNo: 0,
        OrderAmount: cartItems?.grandTotal?.raw?.withTax,
        PaidAmount: 0.0,
        BalanceAmount: 0.0,
        IsValid: false,
        Status: PaymentOrderStatus.PENDING,
        AuthCode: null,
        IssuerUrl: null,
        PaRequest: null,
        PspSessionCookie: null,
        PspResponseCode: null,
        PspResponseMessage: null,
        PaymentGatewayId: method?.id,
        PaymentGateway: method?.systemName,
        Token: null,
        PayerId: null,
        CvcResult: null,
        AvsResult: null,
        Secure3DResult: null,
        CardHolderName: null,
        IssuerCountry: null,
        Info1: null,
        FraudScore: null,
        PaymentMethod: paymentMethodName ?? method?.systemName,
        IsVerify: false,
        IsValidAddress: false,
        LastUpdatedBy: null,
        OperatorId: null,
        RefStoreId: null,
        TillNumber: null,
        ExternalRefNo: null,
        ExpiryYear: null,
        ExpiryMonth: null,
        IsMoto: false,
        additionalServiceCharge: additionalServiceCharge,
      },
    }

    const handleAsync = async () => {
      try {
        if (eta?.dueDays && eta?.dueDays > 0) {
          confirmOrderInput.dueDays = eta.dueDays;
        } else {
          const requestData = { 
            basketId : isChannelStore ? storeBasketId : basketId, 
            user, 
            shippingAddr : shippingClone, 
            isChannelStore 
          }
          const etaResponse: any = await fetchEDDAsync(requestData)
          if (etaResponse?.dueDays) {
            confirmOrderInput.dueDays = etaResponse.dueDays;
          }
        }

        const billableAmount = isTestPayment
          ? TEST_PAYMENT_AMOUNT
          : cartItems?.grandTotal?.raw?.withTax?.toFixed(2)
        const amountFormatted = `${cartItems?.currencySymbol}${
          isTestPayment
            ? TEST_PAYMENT_AMOUNT
            : cartItems?.grandTotal?.raw?.withTax?.toFixed(2)
        }`
        setBillableAmount({
          amount: cartItems?.grandTotal?.raw?.withTax?.toFixed(2),
          amountFormatted: cartItems?.grandTotal?.formatted?.withTax,
          lineItems: cartItems?.lineItems,
        })

        const orderResponse: any = await createStorefrontOrder( isChannelStore ? storeBasketId : basketId, confirmOrderInput, isChannelStore )
        recordFacebookPaymentInfo(orderResponse?.result?.payment?.paymentMethod)

        if (orderResponse?.message) {
          return orderResponse?.message
        }

        if (orderResponse?.result?.id) {
          let basketAmount = toNumber(
            cartItems?.grandTotal?.raw?.withTax?.toFixed(2)
          )
          if (
            matchStrings(
              orderResponse?.result?.payment?.paymentMethod,
              GOKWIK_COD_METHOD_SYSTEM_NAME || '',
              true
            )
          ) {
            const additionalServiceCharge: any = getAdditionalCharges(method)

            if (additionalServiceCharge) {
              basketAmount =
                basketAmount + toNumber(additionalServiceCharge?.toString())
            }
          }

          // If basket grand total & order value doesn't match.
          if (basketAmount !== orderResponse?.result?.payment?.orderAmount) {
            return 'BASKET_AND_ORDER_VALUE_MISMATCH'
          }

          // Cookies.remove(UtmCookieKey)
          if (orderResponse?.result?.payments?.length) {
            const validPayment = orderResponse?.result?.payments?.find(
              (x: any) => x?.isValid
            )
            if (
              validPayment &&
              validPayment?.status === PaymentOrderStatus.PAID
            ) {
              return 'PAYMENT_ALREADY_PROCCESSED'
            }
          }

          // handlePayments(method)
          const encryptedOrderResponse = encrypt(
            JSON.stringify(orderResponse?.result)
          )
          localStorage.setItem(
            LocalStorage.Key.ORDER_RESPONSE,
            encryptedOrderResponse
          )

          const orderModel = getOrderModelData({
            orderResponse,
            method,
            billableAmount,
          })

          const encryptedOrderPayment = encrypt(JSON.stringify(orderModel))
          localStorage.setItem(
            LocalStorage.Key.ORDER_PAYMENT,
            encryptedOrderPayment
          )

          const orderInfo = {
            orderResponse: orderResponse,
            order: orderModel,
            jusPayOrder: {},
          }

          const encryptedConvertedOrder = encrypt(JSON.stringify(orderInfo))
          localStorage.setItem(
            LocalStorage.Key.CONVERTED_ORDER,
            encryptedConvertedOrder
          )
          setOrderInfo(orderInfo)
        }
      } catch (error) {
        //window.alert(error)
        console.error(':: error ', error)
      }
      return ''
    }
    return await handleAsync()
  }

  const getEDDDays = ( 
    setEta:any,
    isChannelStore:any,
    storeBasketId:any,
    basketId:any,
    user:any,
    cartItems:any,
    shippingAddr:any
  ) => {
    try {
      const eddInput = {
        basketId: isChannelStore ? storeBasketId : basketId,
        userId:
          user?.userId && user?.userId != EmptyGuid
            ? user?.userId
            : cartItems?.userId,
        shippingPostCode: numberToString(shippingAddr?.postCode)
      }

      const requestData = {
        model: eddInput,
        ...(isChannelStore && { isChannelStore: true }),
      };

      axios
        .post(NEXT_DELIVERABILITY_ETA, requestData)
        .then(({ data: etaResult }) => {
          setEta(etaResult);
        })
    } catch (error) {
      console.error(':: error ', error)
    }

  }
  
  const confirmJusPayOrder = async (
    method: any,
    paymentMethodName?: string,
    paymentMethodInfo?: string,
    savePspInfo: boolean = true
  ) => {
    savePspInfo = matchStrings(
      paymentMethodName ?? '',
      GOKWIK_COD_METHOD_SYSTEM_NAME ?? '',
      true
    )
      ? false
      : true

    const decryptedOrderModal = decrypt(
      localStorage?.getItem(LocalStorage.Key.ORDER_PAYMENT) as string
    )
    const orderModel = JSON.parse(decryptedOrderModal)
    const decryptedOrderResponse = decrypt(
      localStorage?.getItem(LocalStorage.Key.ORDER_RESPONSE) as string
    )
    const orderResponse = {
      result: JSON.parse(decryptedOrderResponse),
    }

    const billingInfoClone = { ...defaultShippingAddress }
    //delete billingInfoClone.id;
    const shippingClone = { ...defaultShippingAddress }
    //delete shippingClone.id;

    const confirmOrderInput = {
      billingAddress: {
        ...billingInfoClone,
        country:
          shippingCountry?.twoLetterIsoCode || BETTERCOMMERCE_DEFAULT_COUNTRY,
        countryCode:
          shippingCountry?.twoLetterIsoCode || BETTERCOMMERCE_DEFAULT_COUNTRY,
      },
      shippingAddress: {
        ...shippingClone,
        country:
          shippingCountry?.twoLetterIsoCode || BETTERCOMMERCE_DEFAULT_COUNTRY,
        countryCode:
          shippingCountry?.twoLetterIsoCode || BETTERCOMMERCE_DEFAULT_COUNTRY,
      },
    }

    let jusPayDetails: any = getJusPayOfferDetails({
      applicableCoupon,
      applicableOffers,
    })
    const authToken =
      localStorage?.getItem(LocalStorage.Key.userEncryptedDetails) ?? ''
    const juspayOrderData = {
      txnOrderId: orderResponse?.result?.id,
      orderId: getOrderId(orderModel), //orderResponse?.result?.id,
      productId: cartItems?.id,
      customerId: getUserId(),
      currencyCode: cartItems?.baseCurrency,
      customerEmail: cartItems?.userEmail,
      customerPhone: defaultShippingAddress?.phoneNo,
      billingAddress: confirmOrderInput?.billingAddress,
      shippingAddress: confirmOrderInput?.shippingAddress,
      twoLetterIsoCode:
        shippingCountry?.twoLetterIsoCode || BETTERCOMMERCE_DEFAULT_COUNTRY,
      mobileCountryCode: BETTERCOMMERCE_DEFAULT_COUNTRY_CODE,
      applyOffersDisabled: APPLY_JUSPAY_OFFERS_DISABLED,
      jusPayDetails,
      methodId: method?.id,
      methodName: paymentMethodName ?? method?.systemName,
      paymentMethodInfo,
      savePspInfo,
      authToken,
    }

    const { data: jusPayOrderResult } = await createJusPayOrder(juspayOrderData)

    const orderInfoUpdated = await getOrderInfo()
    if (orderInfoUpdated) {
      orderInfoUpdated.jusPayOrder = jusPayOrderResult
      const encryptedConvertedOrder = encrypt(JSON.stringify(orderInfoUpdated))
      localStorage.setItem(
        LocalStorage.Key.CONVERTED_ORDER,
        encryptedConvertedOrder
      )
    }
    setOrderInfo(orderInfoUpdated)
    return jusPayOrderResult
  }

  const initiateOrder = async ( eta:any, paymentMethodName?: string) => {
    let msgCode = ''
    resetCartStorage()
    // Fetch payment methods
    let paymentMethods: any = []
    if (!paymentMethod?.length) {
      const response = await fetchPaymentMethod(cartItems, shippingCountry)
      paymentMethods = response?.data
    } else {
      paymentMethods = paymentMethod
    }

    const jusPayPaymentMethod = paymentMethods?.length
      ? paymentMethods?.find((x: any) =>
          matchStrings(x?.systemName, juspayPaymentMethodName || '', true)
        )
      : undefined

    let shippingMethod = cartItems?.shippingMethods?.length
      ? cartItems?.shippingMethods[0]
      : null
    //const billableAmount = isTestPayment ? TEST_PAYMENT_AMOUNT : cartItems?.grandTotal?.raw?.withTax?.toFixed(2);

    if (!shippingMethod?.id) {
      const { data: shippingMethodsResult }: any = await axios.post(
        NEXT_SHIPPING_ENDPOINT,
        {
          basketId,
          countryCode:
            shippingCountry?.twoLetterIsoCode || BETTERCOMMERCE_DEFAULT_COUNTRY,
        }
      )

      if (shippingMethodsResult?.length) {
        shippingMethod = shippingMethodsResult[0]
      }
    }

    if (jusPayPaymentMethod?.id && shippingMethod?.id) {
      let tempDefaultBillingAddress = {}
      let tempDefaultShippingAddress = {}
      if (!defaultBillingAddress || defaultBillingAddress.id === '000') {
        tempDefaultBillingAddress = checkForDefaultBillingAddress(
          defaultBillingAddress
        )
      }
      if (!defaultShippingAddress || defaultShippingAddress.id === '000') {
        tempDefaultShippingAddress = checkForDefaultBillingAddress(
          defaultShippingAddress
        )
      }

      const addressInfo =
        (defaultShippingAddress && defaultShippingAddress.id === '000'
          ? null
          : defaultShippingAddress) ||
        (defaultBillingAddress && defaultBillingAddress.id === '000'
          ? null
          : defaultBillingAddress) ||
        tempDefaultShippingAddress ||
        tempDefaultBillingAddress

      if (!localStorage?.getItem(LocalStorage.Key.CONVERTED_ORDER)) {
        msgCode = await confirmOrder(
          eta,
          jusPayPaymentMethod,
          addressInfo,
          shippingMethod,
          paymentMethodName
        )
      } else {
        const amountFormatted = `${cartItems?.currencySymbol}${
          isTestPayment
            ? TEST_PAYMENT_AMOUNT
            : cartItems?.grandTotal?.raw?.withTax?.toFixed(2)
        }`
        setBillableAmount({
          amount: cartItems?.grandTotal?.raw?.withTax?.toFixed(2),
          amountFormatted: cartItems?.grandTotal?.formatted?.withTax,
          lineItems: cartItems?.lineItems,
        })

        const convertedOrder: any = localStorage?.getItem(
          LocalStorage.Key.CONVERTED_ORDER
        )
        setOrderInfo(JSON.parse(convertedOrder))
      }
    }
    return msgCode
  }

  const initiateJusPayOrder = async (
    paymentMethodName: string,
    isCOD: boolean = false,
    paymentMethodInfo: string = '',
    savePspInfo: boolean = true
  ) => {
    const orderInfo = await getOrderInfo()
    if (!orderInfo?.jusPayOrder?.orderId) {
      const jusPayPaymentMethod = !isCOD
        ? paymentMethods?.length
          ? paymentMethods?.find((x: any) =>
              matchStrings(x?.systemName, juspayPaymentMethodName || '', true)
            )
          : undefined
        : paymentMethods?.length
        ? paymentMethods?.find((x: any) =>
            matchStrings(x?.systemName, paymentMethodName, true)
          )
        : undefined
      const justPayOrderResult = await confirmJusPayOrder(
        jusPayPaymentMethod,
        paymentMethodName,
        paymentMethodInfo,
        savePspInfo
      )
      return justPayOrderResult
    }
    return orderInfo?.jusPayOrder
  }

  const handleConvertOrder = async ( eta:any, paymentMethodName?: string,) => {
    if (defaultShippingAddress?.id || defaultBillingAddress?.id) {
      const msgCode = await initiateOrder(eta, paymentMethodName)
      if (msgCode) {
        if (Messages.Errors[msgCode]) {
          setAlert({ type: 'error', msg: Messages.Errors[msgCode] })
        } else {
          setAlert({ type: 'error', msg: msgCode })
        }
      }

      if (CONVERT_ORDER_VALIDATION_MESSAGES.includes(msgCode)) {
        setTimeout(async () => {
          await generateNewBasketAndRedirect()
        }, 500)
      }
      return !msgCode
    }
    return false
  }

  return {
    confirmOrder,
    confirmJusPayOrder,
    initiateOrder,
    initiateJusPayOrder,
    handleConvertOrder,
    getEDDDays
  }
}

export { useOrders }
