import { recordGA4Event } from '@components/services/analytics/ga4'
import {
  BETTERCOMMERCE_DEFAULT_CURRENCY,
  CHECKOUT_FLOW_TYPES,
  EmptyGuid,
  FirebaseKeys,
} from '@components/utils/constants'
import { LocalStorage } from '@components/utils/payment-constants'
import { getSessionItem } from '@components/utils/sessionStorage'
import { getKwikPassFields, getLineItemSKU, getRemoteConfigAsBoolean, getRemoteConfigAsString } from '@framework/utils/app-util'
import { GA_EVENT } from 'hooks/ga_event.modal'
import { MO_ENGAGE_EVENT } from 'hooks/moengage_event.modal'
import { PAYMENT_TABS } from './helper'
import { getEncryptedNumber } from '@framework/utils/cipher'
import { getUserNumber } from '@components/account/loginHelper'
import { stringToBoolean, tryParseJson } from '@framework/utils/parse-util'

interface IInitiatePaymentProps {
  cartItems: any
  billableAmount: any
  user: any
  isGoKwikUser: boolean
  addressesList: Array<any>
}

interface IPaymentScreenViewedProps {
  router: any
  isGoKwikUser: boolean
  addressesList: Array<any>
  showCod?: any
  getAdditionalCharges?: any
  user?: any
}

interface IPreferredPaymentsEventProps {
  user: any
  isGoKwikUser: boolean
  addressesList: Array<any>
  trackMoEngageEvent: any
  isChannelStore: boolean
}

interface IHandleSetPaymentModeEvent {
  mode: any
  user: any
  isGoKwikUser: boolean
  addressesList: Array<any>
  trackMoEngageEvent: any
  isChannelStore: boolean
}

interface IPurchaseEventProps {
  payment: any
  cartItems: any
  user: any
  isGoKwikUser: boolean
  addressesList: Array<any>
}

interface IOrderModelDataProps {
  orderResponse: any
  method: any
  billableAmount: any
}

export const handleInitiatePaymemtGAEvent = (props: IInitiatePaymentProps) => {
  const { cartItems, billableAmount, user, isGoKwikUser, addressesList } = props
  const mobileRSA = getEncryptedNumber(getUserNumber(user))
  const gaPayload = {
    ecommerce: {
      loggedIn: true,
      items: cartItems?.lineItems?.map((item: any) => ({
        item_name: item?.name,
        price: item?.price?.raw?.withTax,
        quantity: item?.qty,
        item_brand: item?.brand,
        item_id: item?.sku,
        item_size: item.attributesJson && JSON.parse(item.attributesJson).Size,
        item_variant: item?.colorName,
        item_var_id: item?.stockCode,
      })),
      total_value: billableAmount.amount,
      loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
      address_source: isGoKwikUser ? getRemoteConfigAsString(FirebaseKeys.CHECKOUT_FLOW) === CHECKOUT_FLOW_TYPES.PRE ? 'GoKwik' : 'kwikpass' : 'Damensch',
      mobile_number: mobileRSA,
      ...getKwikPassFields(),
    },
  }
  recordGA4Event(window, GA_EVENT.INITIATE_PAYMENT, gaPayload)
}

export const handlePaymentScreenViewedGAEvent = (
  props: IPaymentScreenViewedProps
) => {
  const { router, isGoKwikUser, addressesList, showCod = false, getAdditionalCharges, user } = props
  const prevPath: any = router?.query?.address_type
  const methods = PAYMENT_TABS?.map(x => x?.label) || [];
  const mobileRSA = getEncryptedNumber(getUserNumber(user))
  const gaPayload = {
    previous_page: prevPath,
    address_source: isGoKwikUser ? getRemoteConfigAsString(FirebaseKeys.CHECKOUT_FLOW) === CHECKOUT_FLOW_TYPES.PRE ? 'GoKwik' : 'kwikpass' : 'Damensch',
    UPI: methods?.includes('UPI'),
    Credit_and_Debit_cards: methods?.includes('Credit & Debit Cards'),
    Wallets: methods?.includes('Wallets'),
    Netbanking: methods?.includes('Netbanking'),
    COD: methods?.includes('Pay on Delivery') && !!showCod,
    cod_value: getAdditionalCharges(),
    mobile_number: mobileRSA,
    ...getKwikPassFields(),
  }
  recordGA4Event(window, GA_EVENT.PAYMENT_INFO_SCREEN_VIEWED, gaPayload)
}

export const handlePreferredPaymentEvents = (
  props: IPreferredPaymentsEventProps
) => {
  const {
    user,
    isGoKwikUser,
    addressesList,
    trackMoEngageEvent,
    isChannelStore,
  } = props
  recordGA4Event(window, GA_EVENT.PAYMENT_FIRSTSTEP, {
    mode_type: 'Preferred Payment',
    loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
    address_source: isGoKwikUser
      ? addressesList && addressesList?.length > 0
        ? 'GoKwik'
        : 'New'
      : 'Damensch',
    enable_gokwik_checkout : getRemoteConfigAsBoolean(FirebaseKeys.ENABLE_GOKWIK_CHECKOUT),
  })

  if (!isChannelStore) {
    trackMoEngageEvent(
      MO_ENGAGE_EVENT.CHECKOUT_PAYMODE,
      {
        mode_type: 'Preferred Payment',
      },
      window
    )
  }
}

export const handleUPIListEvents = (props: IPreferredPaymentsEventProps) => {
  const {
    user,
    isGoKwikUser,
    addressesList,
    trackMoEngageEvent,
    isChannelStore,
  } = props

  recordGA4Event(window, GA_EVENT.PAYMENT_FIRSTSTEP, {
    mode_type: 'UPI',
    loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
    address_source: isGoKwikUser
      ? addressesList && addressesList?.length > 0
        ? 'GoKwik'
        : 'New'
      : 'Damensch',
    enable_gokwik_checkout : getRemoteConfigAsBoolean(FirebaseKeys.ENABLE_GOKWIK_CHECKOUT),
  })

  if (!isChannelStore) {
    trackMoEngageEvent(
      MO_ENGAGE_EVENT.CHECKOUT_PAYMODE,
      {
        mode_type: 'UPI',
      },
      window
    )
  }
}

export const handleCardsListEvents = (props: IPreferredPaymentsEventProps) => {
  const { user, isGoKwikUser, addressesList, trackMoEngageEvent } = props

  recordGA4Event(window, GA_EVENT.PAYMENT_FIRSTSTEP, {
    mode_type: 'Card',
    loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
    address_source: isGoKwikUser
      ? addressesList && addressesList?.length > 0
        ? 'GoKwik'
        : 'New'
      : 'Damensch',
    enable_gokwik_checkout : getRemoteConfigAsBoolean(FirebaseKeys.ENABLE_GOKWIK_CHECKOUT),
  })

  trackMoEngageEvent(
    MO_ENGAGE_EVENT.OFFER_POPUP,
    {
      mode_type: 'Card',
    },
    window
  )
}

export const handleWalletsEvents = (props: IPreferredPaymentsEventProps) => {
  const {
    user,
    isGoKwikUser,
    addressesList,
    trackMoEngageEvent,
    isChannelStore,
  } = props

  recordGA4Event(window, GA_EVENT.PAYMENT_FIRSTSTEP, {
    mode_type: 'Wallets',
    loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
    address_source: isGoKwikUser
      ? addressesList && addressesList?.length > 0
        ? 'GoKwik'
        : 'New'
      : 'Damensch',
    enable_gokwik_checkout : getRemoteConfigAsBoolean(FirebaseKeys.ENABLE_GOKWIK_CHECKOUT),
  })

  if (!isChannelStore) {
    trackMoEngageEvent(
      MO_ENGAGE_EVENT.CHECKOUT_PAYMODE,
      {
        mode_type: 'Wallets',
      },
      window
    )
  }
}

export const handleNetbankingEvents = (props: IPreferredPaymentsEventProps) => {
  const {
    user,
    isGoKwikUser,
    addressesList,
    trackMoEngageEvent,
    isChannelStore,
  } = props

  recordGA4Event(window, GA_EVENT.PAYMENT_FIRSTSTEP, {
    mode_type: 'Netbanking',
    loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
    address_source: isGoKwikUser
      ? addressesList && addressesList?.length > 0
        ? 'GoKwik'
        : 'New'
      : 'Damensch',
    enable_gokwik_checkout : getRemoteConfigAsBoolean(FirebaseKeys.ENABLE_GOKWIK_CHECKOUT),
  })

  if (isChannelStore) {
    trackMoEngageEvent(
      MO_ENGAGE_EVENT.CHECKOUT_PAYMODE,
      {
        mode_type: 'Netbanking',
      },
      window
    )
  }
}

export const handleCODEvents = (props: IPreferredPaymentsEventProps) => {
  const {
    user,
    isGoKwikUser,
    addressesList,
    trackMoEngageEvent,
    isChannelStore,
  } = props

  recordGA4Event(window, GA_EVENT.PAYMENT_FIRSTSTEP, {
    mode_type: 'COD',
    loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
    address_source: isGoKwikUser
      ? addressesList && addressesList?.length > 0
        ? 'GoKwik'
        : 'New'
      : 'Damensch',
    enable_gokwik_checkout : getRemoteConfigAsBoolean(FirebaseKeys.ENABLE_GOKWIK_CHECKOUT),
  })
  recordGA4Event(window, GA_EVENT.CHECKOUT_PAYMODE, {
    mode_type: 'COD',
    loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
    address_source: isGoKwikUser
      ? addressesList && addressesList?.length > 0
        ? 'GoKwik'
        : 'New'
      : 'Damensch',
    enable_gokwik_checkout : getRemoteConfigAsBoolean(FirebaseKeys.ENABLE_GOKWIK_CHECKOUT),
  })

  if (!isChannelStore) {
    trackMoEngageEvent(
      MO_ENGAGE_EVENT.CHECKOUT_PAYMODE,
      {
        mode_type: 'COD',
      },
      window
    )
  }
}

export const handleSetPaymentModeEvent = (
  props: IHandleSetPaymentModeEvent
) => {
  const {
    mode,
    user,
    isGoKwikUser,
    addressesList,
    trackMoEngageEvent,
    isChannelStore,
  } = props

  if (mode?.key == 'preferredpayment') {
    handlePreferredPaymentEvents({
      user,
      isGoKwikUser,
      addressesList,
      trackMoEngageEvent,
      isChannelStore,
    })
  }
  if (mode?.key == 'upi-list') {
    handleUPIListEvents({
      user,
      isGoKwikUser,
      addressesList,
      trackMoEngageEvent,
      isChannelStore,
    })
  }
  if (mode?.key == 'cards-list') {
    handleCardsListEvents({
      user,
      isGoKwikUser,
      addressesList,
      trackMoEngageEvent,
      isChannelStore,
    })
  }
  if (mode?.key == 'wallets') {
    handleWalletsEvents({
      user,
      isGoKwikUser,
      addressesList,
      trackMoEngageEvent,
      isChannelStore,
    })
  }
  if (mode?.key == 'netbanking') {
    handleNetbankingEvents({
      user,
      isGoKwikUser,
      addressesList,
      trackMoEngageEvent,
      isChannelStore,
    })
  }
  if (mode?.key == 'payondelivery') {
    handleCODEvents({
      user,
      isGoKwikUser,
      addressesList,
      trackMoEngageEvent,
      isChannelStore,
    })
  }
}

export const getPurchaseGAEventData = (props: IPurchaseEventProps) => {
  const { payment, cartItems, user, isGoKwikUser, addressesList } = props
  const mobileRSA = getEncryptedNumber(getUserNumber(user))
  return {
    transaction_id: payment ? `${payment?.orderNo}-${payment?.id}` : '',
    affiliation: 'Online Store',
    value: payment?.orderAmount || '',
    shipping: cartItems?.shippingCharge?.raw?.withTax ?? 0,
    currency: cartItems?.currencyCode || BETTERCOMMERCE_DEFAULT_CURRENCY,
    loggedIn: true,
    coupon: cartItems?.promotionsApplied?.length
      ? cartItems?.promotionsApplied?.map((x: any) => x?.promoCode)
      : '',
    items: cartItems?.lineItems?.map((item: any) => ({
      item_name: item?.name,
      price: item?.price?.raw?.withTax,
      quantity: item?.qty,
      item_id: getLineItemSKU(item?.attributesJson),
      item_size: item?.variantProducts
        ?.find((x: any) => x?.stockCode === item?.stockCode)
        ?.variantAttributes?.find((x: any) => x?.fieldCode === 'clothing.size')
        ?.fieldValue,
      item_brand: item?.brand,
      item_var_id: item?.stockCode,
      item_variant: item?.colorName,
    })),
    loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
    address_source: isGoKwikUser ? getRemoteConfigAsString(FirebaseKeys.CHECKOUT_FLOW) === CHECKOUT_FLOW_TYPES.PRE ? 'GoKwik' : 'kwikpass' : 'Damensch',
    mobile_number: mobileRSA,
    ...getKwikPassFields(),
  }
}

export const getOrderModelData = (props: IOrderModelDataProps) => {
  const { orderResponse, method, billableAmount } = props
  return {
    id: orderResponse?.result?.payment?.id,
    cardNo: null,
    orderNo: orderResponse?.result.orderNo,
    orderAmount: orderResponse?.result?.grandTotal?.raw?.withTax,
    paidAmount: orderResponse?.result?.grandTotal?.raw?.withTax,
    balanceAmount: '0.00',
    isValid: true,
    status: 2,
    authCode: null,
    issuerUrl: null,
    paRequest: null,
    pspSessionCookie: null,
    pspResponseCode: null,
    pspResponseMessage: null,
    paymentGatewayId: method?.id,
    paymentGateway: method?.systemName,
    token: null,
    payerId: null,
    cvcResult: null,
    avsResult: null,
    secure3DResult: null,
    cardHolderName: null,
    issuerCountry: null,
    info1: '',
    fraudScore: null,
    paymentMethod: method?.systemName,
    cardType: null,
    operatorId: null,
    refStoreId: null,
    tillNumber: null,
    externalRefNo: null,
    expiryYear: null,
    expiryMonth: null,
    isMoto: false,
    upFrontPayment: false,
    upFrontAmount: `${billableAmount}`,
    isPrePaid: false,
  }
}

interface StoreDetails {
  id: string;
  name: string;
  address1: string;
  address2: string;
  city: string;
  countryCode: string;
  countryName: string;
  state: string;
  postCode: string;
  email: string;
  isActive: boolean;
  storeCode: string;
}

export const getStoreInfo = (cookies: any, ignoreCheck = false): StoreDetails | null => {
  const isChannelStore = stringToBoolean(cookies[LocalStorage.Key.IS_OFFLINE_STORE]) || ignoreCheck;
  if (!isChannelStore) return null;
  return tryParseJson(cookies?.storeDetails) as StoreDetails | null;
};