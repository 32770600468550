import { FC } from 'react'
import { IDeviceInfo, useUI } from '@components/ui/context'
import { useState } from 'react'
import { LoadingDots } from '@components/ui'
import { getCurrentPage, openNewCartPage } from '@framework/utils/app-util'
import { EmptyGuid } from '@components/utils/constants'
import { tryParseJson } from '@framework/utils/parse-util'
import { getItem } from '@components/utils/localStorage'
import { LocalStorage } from '@components/utils/payment-constants'
import { GA_EVENT } from 'hooks/ga_event.modal'
import { recordGA4Event } from '@components/services/analytics/ga4'
import { IMAGE_ASSET_BASE_URL } from '@framework/utils/constants'

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

interface Props {
  className?: string
  title?: string
  action: any
  buttonType?: string
  type?: string
  colorScheme?: any
  validateAction?: any
  disabled?: any
  section?: string
}

const DEFAULT_COLOR_SCHEME = {
  bgColor: 'bg-black',
  hoverBgColor: 'bg-red-400',
  focusRingColor: 'ring-gray-600',
}

const DefaultButton: FC<Props> = ({
  className = '',
  title = 'Add',
  buttonType = 'cart',
  action = () => {},
  colorScheme = DEFAULT_COLOR_SCHEME,
  validateAction = null,
  disabled,
  section,
}) => {
  const [isLoading, setIsLoading] = useState(false)
  const currentPage = getCurrentPage()
  const deviceInfo = getItem(LocalStorage.Key.DEVICE_INFO_LOCAL_STORAGE)
  let isMobile = false
  if (deviceInfo) {
    const parseDeviceInfo = tryParseJson(deviceInfo) as IDeviceInfo
    isMobile = !!parseDeviceInfo?.isMobile
  }
  const { openCart, setLoaderState, user } = useUI()

  const handleAction = () => {
    setIsLoading(true)
    setLoaderState(true)
    if (buttonType === 'cart') {
      if (validateAction) {
        validateAction().then((status: boolean) => {
          if (status) {
            action().then((status: boolean) => {
              setIsLoading(false)
              setLoaderState(false)
              if (status) {
                // openCart();
                openNewCartPage(openCart, 'cart_icon')
              }
            })
          } else {
            setIsLoading(false)
            setLoaderState(false)
          }
        })
      } else {
        action().then((status: boolean) => {
          setIsLoading(false)
          setLoaderState(false)
          if (status) {
            // openCart();
            openNewCartPage(openCart, 'cart_icon')
          }
        })
      }
    } else {
      recordGA4Event(window, GA_EVENT.NOTIFY_CLICK, {
        current_page: `${currentPage}${section ? ` ${section}` : ''}`,
        button: 'notify me',
        device: isMobile ? 'mobile web' : 'desktop web',
        loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
      })

      action().then(() => {
        setIsLoading(false)
        setLoaderState(false)
      })
    }
  }

  const isButtonDisabled = () => {
    if (disabled) {
      return disabled
    } else if (isLoading) {
      return isLoading
    } else {
      return false
    }
  }

  const { bgColor, hoverBgColor, focusRingColor } = colorScheme

  return (
    <button
      disabled={isButtonDisabled()}
      onClick={handleAction}
      type="button"
      className={classNames(
        isLoading
          ? 'bg-gray-400 border-2 border-gray-400 focus:bg-gray-400 hover:bg-gray-400'
          : `${bgColor} border-2 border-black hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-50 focus:${focusRingColor}`,
        `xs:max-w-xs flex-1 btn-basic-property-big rounded-sm sm:py-3 py-1 sm:px-8 px-1 flex items-center justify-center font-medium text-white hover:text-white  sm:w-full ${className}`
      )}
    >
      <span className="relative pl-1">
        {isLoading ? (
          <LoadingDots />
        ) : (
          <>
            <span className="relative pr-1 top-1 cart-image">
              {title == 'Notify me' && (
                <span className="relative leading-none right-1 -top-px">
                  <i className="sprite-icon sprite-white-whatsapp"></i>
                </span>
              )}
              {title != 'Notify me' && (
                <img
                  alt="Add to bag"
                  src={`${IMAGE_ASSET_BASE_URL}/icons/add-to-bag.svg`}
                  width={20}
                  height={20}
                  className="relative right-0 inline-block pr-0 -top-1"
                />
              )}
            </span>
            <span>{title}</span>
          </>
        )}
      </span>
    </button>
  )
}

export default DefaultButton
