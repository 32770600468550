import { IMG_PLACEHOLDER } from '@components/utils/textVariables'
import Link from 'next/link'
import imagePlaceHolder from 'public/imagePlaceHolder.jpg'
import {
  getCategories,
  getColor,
  getColorAndSize,
  handleBrokenImageError,
  removeSucceedingSlug,
} from '@framework/utils/app-util'
import { priceFormat } from '@framework/utils/parse-util'
import { useEffect } from 'react'
import { useGoogleAnalyticsEvent } from 'hooks/useGAEvent'
import { GA_EVENT } from 'hooks/ga_event.modal'
import { EmptyGuid } from '@components/utils/constants'
import { useUI } from '@components/ui'
import { useRouter } from 'next/router'

const OfferItemCard = (props: any) => {
  const { details, key, handleSoftAddProduct } = props
  const offPercentage = Math.floor(
    100 -
      (details?.softaddedproduct_sellingprice / details?.softaddedproduct_mrp) *
        100
  )
  const trackGoogleAnalyticsEvent = useGoogleAnalyticsEvent()
  const { user } = useUI()
  const router = useRouter()

  useEffect(() => {
    const { productDetails } = details
    trackGoogleAnalyticsEvent(
      GA_EVENT.SOFT_ADDED_PRODUCT_VIEW,
      {
        ecommerce: {
          items: {
            item_name: productDetails?.name,
            item_brand: productDetails?.brand,
            item_category: getCategories(productDetails)?.category2,
            item_category2: getCategories(productDetails)?.category,
            item_variant: getColorAndSize(productDetails),
            quantity: 1,
            item_id: productDetails?.sku,
            item_var_id: productDetails?.stockCode,
            price: 549,
            color: getColor(productDetails),
          },
          current_page: 'Cart',
          section_title: 'Bag',
          value: productDetails?.price?.raw?.withTax,
          loggedin_status: !!(user?.userId && user?.userId !== EmptyGuid),
        },
      },
      window
    )
  }, [])

  return (
    <div
      className="flex max-w-full gap-3 px-5 py-4 sm:px-8 sm:gap-6 bg-[#eb5b2514]"
      key={key}
      onClick={() => {
        router.push(`/${details?.softaddedproduct_slug}`)
      }}
    >
      <div className="flex-shrink-0 cursor-pointer">
        <Link
          legacyBehavior
          href={`/${removeSucceedingSlug(details?.softaddedproduct_slug)}`}
          as={`/${details?.softaddedproduct_slug}`}
          passHref
        >
          <img
            width={72}
            height={128}
            src={
              details?.softaddedproduct_productimg ||
              IMG_PLACEHOLDER ||
              imagePlaceHolder
            }
            alt={'product.name'}
            className="basket-image"
            onError={(e: any) => handleBrokenImageError(e)}
          />
        </Link>
      </div>
      <div className="flex flex-col flex-1 gap-2">
        <div>
          <div className="flex flex-col gap-2 justify-between font-medium text-gray-900">
            <h3
              className="pr-6 text-[11px] font-semibold text-primary"
              style={{
                wordBreak: 'break-word',
              }}
            >
              {details?.softaddedproduct_cardtitle}
            </h3>
            <div className="font-normal text-[11px] text-[#2510008f] cursor-pointer">
              {details?.softaddedproduct_productname}
            </div>
            <p className="flex items-center font-medium text-12 text-primary">
              {priceFormat(details?.softaddedproduct_sellingprice)}
              {details?.softaddedproduct_mrp >
              details?.softaddedproduct_sellingprice ? (
                <>
                  <span className="px-2 font-normal text-gray-500 line-through text-12">
                    {priceFormat(details?.softaddedproduct_mrp)}
                  </span>
                  <span className="font-medium text-[10px] text-green">
                    {offPercentage}% off
                  </span>
                </>
              ) : null}
            </p>
          </div>
        </div>
        <div className="flex gap-2">
          <span className="min-w-[25px] min-h-[25px] w-[25px]">
            <img
              src={
                details?.softaddedproduct_reviewerimg ||
                IMG_PLACEHOLDER ||
                imagePlaceHolder
              }
              alt={'name'}
              width={24}
              height={24}
              className="h-[25px] w-[25px] object-cover object-center rounded-[50%]"
              onError={(e: any) => handleBrokenImageError(e)}
            />
          </span>
          <p className="text-[10px] font-normal text-[#2510008f]">
            <i>{details?.softaddedproduct_review}</i>
          </p>
        </div>

        <div className="flex">
          <button
            className="flex gap-3 h-[30px] rounded-sm py-[4px] px-3 items-center outline-none bg-black text-white"
            onClick={(e:any) => {
              e.preventDefault()
              e.stopPropagation()
              handleSoftAddProduct(details?.productDetails)
            }}
          >
            <span className="font-normal text-xs">Add to Bag</span>
          </button>
        </div>
      </div>
    </div>
  )
}

export default OfferItemCard
